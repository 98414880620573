module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "Techzaion Blog", // Navigation and Site Title
  siteTitleAlt: "Techzaion Blog", // Alternative Site title for SEO
  siteTitleManifest: "TechzaionBlog",
  siteUrl: "https://techzaion.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteHeadline: "Writing and publishing content", // Headline for schema.org JSONLD
  siteDescription: "Techzaion Blog.", // Your site description
  author: "Pratik", // Author for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@yoopratik", // Twitter Username - Optional
  ogSiteName: "Techzaion", // Facebook Site Name - Optional
  ogLanguage: "en_US", // Facebook Language
  googleAnalyticsID: "UA-47519312-6",

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: "#3498DB",
  backgroundColor: "#2b2e3c"
};
